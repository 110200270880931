<script setup lang="ts">
  import { MpCard } from '@borg/ui';
  import SuggestionsBox from './SuggestionsBox.vue';
  import History from './History.vue';
  import GeoLocationOption from './GeoLocationOption.vue';
  import { injectHistory } from './useHistory';
  import { injectSearchBar } from './useSearchBar';

  const history = injectHistory();
  const searchBar = injectSearchBar();

  const isDropdownVisible = computed(
    () =>
      searchBar.dropdownTarget === 'positions-menu' ||
      searchBar.dropdownTarget === 'locations-menu',
  );

  const positionsPrimaryBoxVisible = computed(() =>
    searchBar.positionsGroupedSuggestions.primary.every(
      (group) => group.items.length > 0 || group.additionalItems.length > 0,
    ),
  );
  const locationsPrimaryBoxVisible = computed(() =>
    searchBar.locationGroupedSuggestions.primary.every(
      (group) => group.items.length > 0 || group.additionalItems.length > 0,
    ),
  );
</script>

<template>
  <div
    id="mp-header-dropdown"
    class="dropdown-wrap"
    @mousedown.prevent
  >
    <Transition>
      <div v-if="isDropdownVisible">
        <MpCard
          v-if="searchBar.dropdownTarget === 'positions-menu'"
          key="positions"
          shadow
          :class="{
            'dropdown': true,
            'dropdown--left': true,
            'dropdown--full-width': history.items.length > 0,
          }"
        >
          <ul class="dropdown__list">
            <History
              v-if="history.items.length > 0"
              :items="history.items"
            />
            <SuggestionsBox
              v-if="positionsPrimaryBoxVisible"
              :selected="searchBar.selectedPositions"
              :show-selected="false"
              :data="searchBar.positionsGroupedSuggestions.primary"
              :is-selected="searchBar.isPositionSelected"
              @suggestion-click="searchBar.togglePosition"
            />
            <SuggestionsBox
              v-if="searchBar.positionsGroupedSuggestions.secondary.length > 0"
              :data="searchBar.positionsGroupedSuggestions.secondary"
              :is-selected="searchBar.isPositionSelected"
              @suggestion-click="searchBar.togglePosition"
            />
          </ul>
        </MpCard>
        <MpCard
          v-else-if="searchBar.dropdownTarget === 'locations-menu'"
          key="locations"
          shadow
          :class="{
            'dropdown': true,
            'dropdown--right': true,
            'dropdown--full-width': history.items.length > 0,
          }"
        >
          <ul class="dropdown__list">
            <History
              v-if="history.items.length > 0"
              :items="history.items"
            />
            <SuggestionsBox
              v-if="locationsPrimaryBoxVisible"
              :selected="searchBar.selectedLocations"
              :data="searchBar.locationGroupedSuggestions.primary"
              :is-selected="searchBar.isLocationSelected"
              @suggestion-click="searchBar.toggleLocation"
            >
              <template #before>
                <GeoLocationOption />
              </template>
            </SuggestionsBox>
            <SuggestionsBox
              :data="searchBar.locationGroupedSuggestions.secondary"
              :is-selected="searchBar.isLocationSelected"
              @suggestion-click="searchBar.toggleLocation"
            />
          </ul>
        </MpCard>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
  .dropdown-wrap {
    position: relative;
    width: 100%;
  }

  .dropdown {
    position: absolute;
    z-index: 6;
    top: 10px;
    width: 65%;

    &__list {
      margin: 0;
      display: grid;
      gap: var(--mp-space-80);
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
    }

    &--full-width {
      width: 100%;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 120ms ease-out;
    transition-delay: 100ms;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
