<script setup lang="ts">
  import { Suggestion } from '@borg/types';
  import { MpInputBoxMulti } from '@borg/ui';
  import Suggestions from '../Suggestions.vue';
  import SuggestionsBox from '../SuggestionsBox.vue';
  import HistoryTrack from './HistoryTrack.vue';
  import { injectDropdownMobile } from './useDropdownMobile';
  import { injectSearchBar } from '../useSearchBar';

  const searchBar = injectSearchBar();
  const dropdownMobile = injectDropdownMobile();
  const typePositionSuggestions = ref<Suggestion[]>([]);
  const search = ref<string>();

  const searchKeyword = useDebounceFn(async (keyword: string) => {
    const result = await positionsService.search(keyword);
    return result.isSuccess ? result.data : [];
  });

  function isPositionSelected(suggestion: Suggestion) {
    return dropdownMobile.selectedPositions.map((item) => item.id).includes(suggestion.id);
  }

  function reset() {
    search.value = '';
    typePositionSuggestions.value = [];
  }

  const onInput = useDebounceFn(async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.value) {
      typePositionSuggestions.value = [];
    } else {
      typePositionSuggestions.value = await searchKeyword(target.value);
    }
  }, 250);

  function onEnterKeyword() {
    if (search.value?.trim()) {
      dropdownMobile.togglePosition({
        id: search.value,
        name: search.value,
      });
      reset();
    }
  }

  function onSelectPosition(suggestion: Suggestion) {
    search.value = '';
    typePositionSuggestions.value = [];
    dropdownMobile.togglePosition(suggestion);
  }
</script>

<template>
  <div>
    <MpInputBoxMulti
      v-model:input="search"
      searchable
      variant="rounded"
      :placeholder="$t('SEARCH_BAR.POSITIONS_PLACEHOLDER')"
      :tags="dropdownMobile.selectedPositions"
      :get-value="(tag) => tag.id"
      :get-label="(tag) => tag.name"
      :show-arrow="false"
      @tag-pop="dropdownMobile.selectedPositions.pop()"
      @tag-close="dropdownMobile.togglePosition"
      @input="onInput"
      @keydown.enter="onEnterKeyword"
      @blur="onEnterKeyword"
    />
    <ul @mousedown.prevent>
      <Suggestions
        :suggestions="typePositionSuggestions"
        :is-selected="isPositionSelected"
        @suggestion-click="onSelectPosition"
      />
    </ul>
    <template v-if="!search">
      <HistoryTrack />
      <ul>
        <SuggestionsBox
          :selected="dropdownMobile.selectedPositions"
          :data="searchBar.positionsGroupedSuggestions.primary"
          :is-selected="isPositionSelected"
          @suggestion-click="onSelectPosition"
        />
        <SuggestionsBox
          :data="searchBar.positionsGroupedSuggestions.secondary"
          :is-selected="isPositionSelected"
          @suggestion-click="onSelectPosition"
        />
      </ul>
    </template>
  </div>
</template>
