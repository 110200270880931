import { Suggestion } from '@borg/types';
import { toggleElementInArray } from '@borg/utils';

export const injectionKey: InjectionKey<ReturnType<typeof useDropdownMobile>> = Symbol('AuthForm');

export function provideDropdownMobile() {
  const dropdownMobile = useDropdownMobile();
  provide(injectionKey, dropdownMobile);

  return dropdownMobile;
}

export function injectDropdownMobile() {
  const dropdownMobile = inject(injectionKey);

  if (!dropdownMobile) {
    throw new Error('DropdownMobile is not provided');
  }

  return dropdownMobile;
}

function useDropdownMobile() {
  const selectedPositions = ref<Suggestion[]>([]);
  const selectedLocations = ref<Suggestion[]>([]);

  function togglePosition(position: Suggestion) {
    selectedPositions.value = toggleElementInArray(
      selectedPositions.value,
      position,
      (pos) => pos.id === position.id,
    );
  }

  function toggleLocation(location: Suggestion) {
    selectedLocations.value = toggleElementInArray(
      selectedLocations.value,
      location,
      (loc) => loc.id === location.id,
    );
  }

  return reactive({
    selectedPositions,
    selectedLocations,
    togglePosition,
    toggleLocation,
  });
}
