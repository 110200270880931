<script setup lang="ts">
  import { Suggestion } from '@borg/types';
  import { MpInputBoxMulti } from '@borg/ui';
  import Suggestions from '../Suggestions.vue';
  import SuggestionsBox from '../SuggestionsBox.vue';
  import GeoLocationOption from '../GeoLocationOption.vue';
  import HistoryTrack from './HistoryTrack.vue';
  import { injectDropdownMobile } from './useDropdownMobile';
  import { injectSearchBar } from '../useSearchBar';

  const searchBar = injectSearchBar();
  const dropdownMobile = injectDropdownMobile();
  const typeLocationSuggestions = ref<Suggestion[]>([]);
  const search = ref('');

  async function searchKeyword(keyword: string) {
    const result = await locationsService.search(keyword);
    return result.isSuccess ? result.data : [];
  }

  function isLocationSelected(suggestion: Suggestion) {
    return dropdownMobile.selectedLocations.map((item) => item.id).includes(suggestion.id);
  }

  const onInput = useDebounceFn(async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.value) {
      typeLocationSuggestions.value = [];
    } else {
      typeLocationSuggestions.value = await searchKeyword(target.value);
    }
  }, 300);

  function onSelectLocation(suggestion: Suggestion) {
    typeLocationSuggestions.value = [];
    dropdownMobile.toggleLocation(suggestion);
  }
</script>

<template>
  <div>
    <MpInputBoxMulti
      v-model:input="search"
      searchable
      variant="rounded"
      :show-arrow="false"
      :placeholder="$t('SEARCH_BAR.LOCATIONS_PLACEHOLDER')"
      :tags="dropdownMobile.selectedLocations"
      :get-value="(tag) => tag.id"
      :get-label="(tag) => tag.name"
      @tag-pop="dropdownMobile.selectedLocations.pop()"
      @tag-close="dropdownMobile.toggleLocation"
      @input="onInput"
    />
    <ul>
      <Suggestions
        :suggestions="typeLocationSuggestions"
        :is-selected="isLocationSelected"
        @suggestion-click="onSelectLocation"
      />
    </ul>
    <template v-if="!search">
      <HistoryTrack />
      <ul class="dropdown__suggestions">
        <SuggestionsBox
          :selected="dropdownMobile.selectedLocations"
          :data="searchBar.locationGroupedSuggestions.primary"
          :is-selected="isLocationSelected"
          @suggestion-click="onSelectLocation"
        >
          <template #before>
            <GeoLocationOption />
          </template>
        </SuggestionsBox>
        <SuggestionsBox
          :data="searchBar.locationGroupedSuggestions.secondary"
          :is-selected="isLocationSelected"
          @suggestion-click="onSelectLocation"
        />
      </ul>
    </template>
  </div>
</template>
