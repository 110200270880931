<script setup lang="ts">
  import { injectHistory } from '../useHistory';

  const history = injectHistory();
</script>

<template>
  <JobSearchBarHistory
    v-if="history.items.length > 0"
    class="history_track"
    :items="history.items"
  />
</template>

<style scoped lang="scss">
  .history_track {
    margin-top: var(--mp-space-60);
    overflow-x: auto;
  }
</style>
