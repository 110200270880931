<script setup lang="ts">
  import { MpBox, MpButton, useMpScreen } from '@borg/ui';
  import JobFilters from './JobFilters.vue';
  import { provideGeoLocation } from './useGeoLocation';
  import { provideSearchBar } from './useSearchBar';
  import SelectPositions from './SelectPositions.vue';
  import SelectLocations from './SelectLocations.vue';
  import DropdownDesktop from './DropdownDesktop.vue';
  import DropdownMobile from './DropdownMobile/DropdownMobile.vue';

  defineProps<{
    showFilters?: boolean;
    showCoverImage?: boolean;
  }>();

  const { breakpoints } = useMpScreen();
  const geoLocation = provideGeoLocation();
  const searchBar = provideSearchBar();

  const isLocationsOpen = computed(() => searchBar.dropdownTarget?.includes('locations'));
  const isPositionsOpen = computed(() => searchBar.dropdownTarget?.includes('positions'));
  const isLargeScreen = computed(() => breakpoints.isLg);
  const showControlsShadow = computed(() => searchBar.dropdownTarget?.includes('menu'));
  const isOverlayVisible = computed(() => !!searchBar.dropdownTarget?.includes('menu'));

  onMounted(() => {
    searchBar.initPositions();
    searchBar.initLocations(geoLocation.myLocation);
  });
</script>

<template>
  <Overlay :visible="isOverlayVisible" />
  <div class="searchbar">
    <div
      :class="{
        'searchbar__controls': true,
        'searchbar__controls--with-shadow': showControlsShadow,
      }"
    >
      <MpBox
        :class="{
          'searchbar__select-position': true,
          'searchbar__select-position--inactive': isLocationsOpen,
          'searchbar__select-position--active': isPositionsOpen,
        }"
      >
        <SelectPositions />
      </MpBox>
      <div class="searchbar__controls-divider" />
      <MpBox
        :class="{
          'searchbar__select-location': true,
          'searchbar__select-location--inactive': isPositionsOpen,
          'searchbar__select-location--active': isLocationsOpen,
        }"
      >
        <SelectLocations />
        <div class="searchbar__controls-btn-container">
          <MpButton
            class="searchbar__controls-btn"
            shape="oval"
            size="lg"
            :text="$t('SEARCH')"
            @click="searchBar.search"
          />
        </div>
      </MpBox>
    </div>
    <DropdownDesktop v-if="isLargeScreen" />
    <DropdownMobile v-else />
  </div>
  <JobFilters v-if="showFilters" />
</template>

<style scoped lang="scss">
  $divider-margin: var(--mp-space-30);
  $divider-width: calc(1px + $divider-margin);
  $width-active: calc(65% - $divider-width);
  $width-inactive: calc(35% - $divider-width);
  $trans-time: 200ms;

  .searchbar {
    --job-search-bar-background-color: var(--mp-color-surface-50);

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;

    @include screen-lg {
      max-width: var(--site-search-bar-width);
    }

    &__controls {
      display: flex;
      flex-direction: column;
      box-shadow: none;
      z-index: 4;

      @include screen-lg {
        flex-direction: row;
        align-items: center;
        padding: var(--mp-space-30);
        border: 1px solid var(--site-search-bar-border-color);
        border-radius: var(--mp-radius-full);
        background-color: var(--job-search-bar-background-color);

        &--with-shadow {
          box-shadow: var(--mp-shadow-medium);
        }
      }
    }

    &__controls-divider {
      width: 100%;
      height: var(--mp-space-30);
      background-color: transparent;

      @include screen-lg {
        width: 1px;
        height: var(--mp-space-60);
        margin: 0 $divider-margin;
        background-color: var(--mp-color-grey-300);
      }
    }

    &__controls-btn-container {
      display: none;

      @include screen-lg {
        display: block;
      }
    }

    &__controls-btn {
      --button-color: var(--site-search-bar-button-color);
      --button-color-dark: var(--site-search-bar-button-hover-color);
      --tmp-button-horizontal-padding: 2.3125em;
    }

    &__select-position {
      display: flex;
      align-items: center;
      width: 100%;
      transition: width $trans-time ease-in-out;
      border: 1px solid var(--site-search-bar-border-color);
      border-radius: var(--mp-radius-full);
      box-shadow: var(--mp-shadow-medium);
      background-color: var(--job-search-bar-background-color);

      @include screen-lg {
        border: none;
        width: calc(50% - $divider-width);
        box-shadow: none;

        &--inactive {
          width: $width-inactive;
        }

        &--active {
          width: $width-active;
        }
      }
    }

    &__select-location {
      display: flex;
      align-items: center;
      width: 100%;
      transition: width $trans-time ease-in-out;
      border: 1px solid var(--site-search-bar-border-color);
      border-radius: var(--mp-radius-full);
      box-shadow: var(--mp-shadow-medium);
      background-color: var(--job-search-bar-background-color);

      @include screen-lg {
        border: none;
        width: calc(50% - $divider-width);
        box-shadow: none;

        &--inactive {
          width: $width-inactive;
        }

        &--active {
          width: $width-active;
        }
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 150ms ease;
    transition-delay: $trans-time;
  }

  .v-enter-from,
  .v-leave-to {
    transition: opacity 100ms ease;
    opacity: 0;
  }
</style>
