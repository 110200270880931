import { GeoCoordinates } from '@borg/types';

class MyGeoLocationService {
  async resolve(): Promise<Required<GeoCoordinates>> {
    const promise = new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const position = await promise;

    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  }

  save(position: Required<GeoCoordinates>) {
    localStorage.setItem('my-geo-location', JSON.stringify(position));
  }

  clear() {
    localStorage.removeItem('my-geo-location');
  }

  getLastResolved(): Required<GeoCoordinates> | undefined {
    if (process.server) {
      return;
    }
    const myLastGeoLoc = localStorage.getItem('my-geo-location');
    if (myLastGeoLoc) {
      return JSON.parse(myLastGeoLoc);
    }
  }
}

export const myGeoLocationService = new MyGeoLocationService();
