<script setup lang="ts">
  import { JobSearch } from '@borg/types';
  import Option from './Option.vue';
  import { injectSearchBar } from './useSearchBar';

  defineProps<{
    items: JobSearch[];
  }>();

  const searchBar = injectSearchBar();
</script>

<template>
  <div class="history">
    <Option
      v-for="historyItem in items"
      :key="historyItem.id"
      class="history__option"
    >
      <JobSearchItem
        :item="historyItem"
        @clicked="searchBar.close()"
      />
    </Option>
  </div>
</template>

<style scoped lang="scss">
  .history {
    display: flex;
    flex-direction: row;
    gap: var(--mp-space-30);
    list-style: none;
    padding: 2px;

    @include no-scrollbar;

    &__option {
      display: inline-flex;
      border-radius: var(--mp-space-30);

      @include screen-lg {
        display: initial;
      }
    }

    @include screen-lg {
      flex-direction: column;
      overflow-x: hidden;
    }
  }
</style>
