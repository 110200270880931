<template>
  <li class="option">
    <slot />
  </li>
</template>

<style scoped lang="scss">
  .option {
    border-bottom: 1px solid var(--mp-color-grey-200);

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      cursor: pointer;
    }

    @include screen-sm {
      border-bottom: none;
    }

    &--pointed {
      outline: 1px solid var(--mp-focus);
      border-radius: 2px;
    }
  }
</style>
