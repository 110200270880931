<script setup lang="ts">
  import { MpButton, pushToastMessage } from '@borg/ui';
  import Option from './Option.vue';
  import { injectSearchBar } from './useSearchBar';
  import { injectGeoLocation } from './useGeoLocation';

  const searchBar = injectSearchBar();
  const geoLocation = injectGeoLocation();

  async function onResolveMyLocation() {
    await geoLocation.resolveMyLocation();
    if (geoLocation.loadingState === 'isSuccess' && geoLocation.myLocation) {
      searchBar.initLocations(geoLocation.myLocation);
    } else if (geoLocation.loadingState === 'hasError') {
      pushToastMessage({
        type: 'warning',
        ...geoLocation.error,
      });
    }
  }
</script>

<template>
  <Option v-if="false && geoLocation.loadingState !== 'isSuccess'">
    <MpButton
      :is-loading="geoLocation.isLoading"
      :disabled="geoLocation.isLoading"
      class="get-geo-location"
      :text="$t('GEOLOCATION.FETCH_MY_LOCATION_BUTTON_LABEL')"
      left-icon="pin"
      variant="ghost"
      style="padding: 0"
      @click="onResolveMyLocation"
    />
  </Option>
</template>

<style scoped lang="scss">
  .get-geo-location {
    --mp-button-ghost-default-text-color: var(--mp-color-grey-black);
    --mp-button-ghost-default-background-color: transparent;
    --mp-button-ghost-default-border-color: transparent;
    --mp-button-ghost-focus-text-color: var(--mp-color-primary-dark);
    --mp-button-ghost-focus-outline-color: var(--mp-color-primary-dark);
    --mp-button-ghost-hover-text-color: var(--mp-color-primary-dark);
    --mp-button-ghost-hover-background-color: transparent;
    --mp-button-ghost-hover-border-color: transparent;

    height: min-content;

    @include dark-mode {
      --mp-button-ghost-default-text-color: var(--mp-color-grey-white);
    }
  }
</style>
