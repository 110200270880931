<script lang="ts" setup>
  import { MpBox, MpSwitch } from '@borg/ui';
  import { isArray, isBoolean, isObject } from '@borg/utils';
  import { DynamicJobFilter, DynamicJobFilterSelect, Item } from '@borg/types';
  import { injectSearchBar } from '../JobSearchBar/useSearchBar';

  const searchBar = injectSearchBar();
  const jobsSearchStore = useJobsSearchStore();
  const jobFilters = jobFiltersRepository.useMany();

  function unpackSelect(filter: DynamicJobFilterSelect): Item | Item[] {
    const value = jobsSearchStore.filters.dynamic[filter.id];
    if (
      filter.type === 'single-select' &&
      !isBoolean(value) &&
      !isArray(value) &&
      isObject(value)
    ) {
      return value;
    }
    if (filter.type === 'multi-select' && isArray(value) && value.length) {
      return value;
    }
    return [];
  }

  function onUpdateSelectable(filter: DynamicJobFilter, option: Item | Item[] | undefined) {
    jobsSearchStore.setDynamicFilterValue(filter.id, option);
    searchBar.search();
  }

  function onUpdateToggleable(filterId: string, value: boolean) {
    jobsSearchStore.setDynamicFilterValue(filterId, value);
    searchBar.search();
  }

  function onToggleNewJobs(value: boolean) {
    jobsSearchStore.setStaticFilterValue('newJobs', value);
    searchBar.search();
  }
</script>

<template>
  <div
    v-if="jobFilters"
    class="job-filters"
  >
    <MpBox
      class="job-filters__box"
      hide-scrollbar
    >
      <div class="job-filters__list">
        <div class="job-filters__special">
          <MpSwitch
            :label="$t('JOB_FILTER.JUST_NEW_SWITCH_LABEL')"
            :model-value="jobsSearchStore.filters.static.newJobs"
            @update:model-value="onToggleNewJobs"
          />
        </div>
        <template
          v-for="filter in jobFilters"
          :key="filter"
        >
          <JobFiltersSelect
            v-if="filter.type === 'single-select'"
            :key="filter.id"
            :model-value="unpackSelect(filter)"
            :title="filter.name"
            mode="single"
            :options="filter.options"
            @update:model-value="onUpdateSelectable(filter, $event)"
          />
          <JobFiltersSelect
            v-if="filter.type === 'multi-select'"
            :key="filter.id"
            :model-value="unpackSelect(filter)"
            :title="filter.name"
            mode="multiple"
            :options="filter.options"
            @update:model-value="onUpdateSelectable(filter, $event)"
          />
          <MpSwitch
            v-if="filter.type === 'toggle'"
            :key="filter.id"
            :model-value="!!jobsSearchStore.filters.dynamic[filter.id]"
            :label="filter.name"
            @update:model-value="onUpdateToggleable(filter.id, $event)"
          />
        </template>
      </div>
    </MpBox>
  </div>
</template>

<style scoped lang="scss">
  .job-filters {
    display: none;
    justify-content: center;

    @include screen-xl {
      display: flex;
    }

    &__box {
      overflow-x: auto;
    }

    &__special {
      border-right: 1px solid var(--mp-color-grey-200);
      padding-right: var(--mp-space-60);
      white-space: nowrap;
    }

    &__list {
      display: flex;
      align-items: flex-end;
      gap: var(--mp-space-60);
    }
  }
</style>
