import { AsyncState, GeoCoordinates } from '@borg/types';

const injectionKey: InjectionKey<ReturnType<typeof useGeoLocation>> = Symbol('geolocation');

export function provideGeoLocation() {
  const geolocation = useGeoLocation();
  provide(injectionKey, geolocation);
  return geolocation;
}

export function injectGeoLocation() {
  const geolocation = inject(injectionKey);

  if (!geolocation) {
    throw new Error('Injection Error: GeoLocation not provided');
  }

  return geolocation;
}

function useGeoLocation() {
  const { t } = useI18n();
  const myLocation = ref<Required<GeoCoordinates | undefined>>(
    myGeoLocationService.getLastResolved(),
  );
  const loadingState = ref<AsyncState>('isInitial');
  const geoPositionError = ref<GeolocationPositionError | null>(null);

  const isLoading = computed(() => loadingState.value === 'inProgress');
  const error = computed(() => {
    const subtitle = () => {
      if (geoPositionError.value) {
        const { code, PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT } = geoPositionError.value;
        if (code === PERMISSION_DENIED) {
          return t('GEOLOCATION.ERROR_TOAST.SUBTITLE_DENIED');
        } else if (code === POSITION_UNAVAILABLE) {
          return t('GEOLOCATION.ERROR_TOAST.SUBTITLE_UNAVAILABLE');
        } else if (code === TIMEOUT) {
          return t('GEOLOCATION.ERROR_TOAST.SUBTITLE_TIMEOUT');
        }
      }
      return t('GEOLOCATION.ERROR_TOAST.SUBTITLE_UNKNOWN_ERROR');
    };
    return {
      title: t('GEOLOCATION.ERROR_TOAST.TITLE'),
      subtitle: subtitle(),
    };
  });

  async function resolveMyLocation() {
    try {
      geoPositionError.value = null;
      loadingState.value = 'inProgress';
      myLocation.value = await myGeoLocationService.resolve();
      myGeoLocationService.save(myLocation.value);
      loadingState.value = 'isSuccess';
    } catch (error) {
      myGeoLocationService.clear();
      if (error instanceof GeolocationPositionError) {
        geoPositionError.value = error;
      }
      loadingState.value = 'hasError';
    }
  }

  return reactive({
    myLocation,
    loadingState,
    isLoading,
    error,
    resolveMyLocation,
  });
}
