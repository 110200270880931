<script setup lang="ts">
  import { MpActionsModal, MpTabs } from '@borg/ui';
  import PositionsTab from './PositionsTab.vue';
  import LocationsTab from './LocationsTab.vue';
  import { provideDropdownMobile } from './useDropdownMobile';
  import { injectSearchBar } from '../useSearchBar';

  const searchBar = injectSearchBar();
  const dropdownMobile = provideDropdownMobile();
  const isOpen = computed(() => searchBar.dropdownTarget !== 'none');
  const activeTabIndex = computed<number>(() =>
    searchBar.dropdownTarget === 'positions-menu' ? 0 : 1,
  );

  function onSearch() {
    searchBar.selectedPositions = dropdownMobile.selectedPositions;
    searchBar.selectedLocations = dropdownMobile.selectedLocations;
    searchBar.close();
    searchBar.search();
  }

  watch(isOpen, (value) => {
    if (value) {
      dropdownMobile.selectedPositions = searchBar.selectedPositions;
      dropdownMobile.selectedLocations = searchBar.selectedLocations;
      document.querySelector<HTMLElement>(':focus')?.blur();
    }
  });
</script>

<template>
  <MpActionsModal
    :is-open="isOpen"
    :confirm-button-label="$t('SEARCH_BAR.MOBILE_DROPDOWN_SEARCH_BUTTON_LABEL')"
    :cancel-button-label="$t('SEARCH_BAR.MOBILE_DROPDOWN_CANCEL_BUTTON_LABEL')"
    size="lg"
    @confirm="onSearch"
    @cancel="searchBar.close"
    @overlay-click="searchBar.close"
  >
    <div
      id="mp-header-dropdown"
      class="dropdown"
    >
      <MpTabs
        :model-value="activeTabIndex"
        :animated="false"
        :tabs="[$t('SEARCH_BAR.POSITIONS_PLACEHOLDER'), $t('SEARCH_BAR.LOCATIONS_PLACEHOLDER')]"
        @change="$event === 0 ? searchBar.open('positions-menu') : searchBar.open('locations-menu')"
      >
        <PositionsTab />
        <LocationsTab />
      </MpTabs>
    </div>
  </MpActionsModal>
</template>

<style scoped lang="scss">
  .dropdown {
    height: 100vh;
    width: 100%;
    overflow-y: auto;

    @include no-scrollbar;
  }
</style>
