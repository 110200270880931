<script setup lang="ts">
  await jobFiltersRepository.getMany();
</script>
<template>
  <div class="filters-container">
    <JobFiltersMobile />
    <JobFiltersDesktop />
  </div>
</template>

<style scoped lang="scss">
  .filters-container {
    width: 100%;

    margin-top: var(--mp-space-60);
    margin-bottom: var(--mp-space-20);

    @include screen-md {
      margin-top: var(--mp-space-50);
    }
  }
</style>
