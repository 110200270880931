<script setup lang="ts">
  import { MpSelect, MpText, useMpScreen } from '@borg/ui';
  import { injectSearchBar } from './useSearchBar';

  const { breakpoints } = useMpScreen();
  const searchBar = injectSearchBar();
  const isLargeScreen = computed(() => breakpoints.isLg);

  async function search(keyword: string) {
    const result = await locationsService.search(keyword);
    return result.isSuccess ? result.data : [];
  }
</script>

<template>
  <MpSelect
    v-model="searchBar.selectedLocations"
    :placeholder="$t('SEARCH_BAR.LOCATIONS_PLACEHOLDER')"
    :options="search"
    :get-value="(option) => option.id"
    :get-label="(option) => option.name"
    :resolve-on-load="false"
    :is-open="searchBar.dropdownTarget === 'locations-autocomplete'"
    :wrap-tags="false"
    :removable-tags="isLargeScreen"
    :show-arrow="false"
    :dropdown-offset="15"
    :dropdown-radius="15"
    :preselect="true"
    mode="multiple"
    size="lg"
    searchable
    variant="ghost"
    :arial-label="$t('SEARCH_BAR.LOCATIONS_PLACEHOLDER')"
    name="locations"
    @click="searchBar.open('locations-menu')"
    @focus="searchBar.open('locations-menu')"
    @blur="isLargeScreen && searchBar.close()"
    @keydown="searchBar.onKeyDown($event, 'locations')"
    @add="searchBar.close()"
    @update:query="searchBar.onUpdateLocationKeyword"
  >
    <template #default="{ option }">
      <div class="option">
        <MpText
          type="body1"
          no-margin
        >
          {{ option.name }}
        </MpText>
      </div>
    </template>
  </MpSelect>
</template>

<style scoped lang="scss">
  .option {
    display: flex;
    align-items: center;
    gap: var(--mp-space-40);
    padding: var(--mp-space-20) var(--mp-space-40);
    cursor: pointer;
  }
</style>
