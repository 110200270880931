import { GeoCoordinates, GroupedSuggestions, Item, Suggestion } from '@borg/types';
import { isEmptyString, isNumber, isString, toggleElementInArray, truncDigits } from '@borg/utils';
import { injectHistory } from '@/components/JobSearchBar/useHistory';

type Dropdown =
  | 'none'
  | 'positions-menu'
  | 'locations-menu'
  | 'positions-autocomplete'
  | 'locations-autocomplete';

const injectionKey: InjectionKey<ReturnType<typeof useSearchBar>> = Symbol('searchbar');

export function provideSearchBar() {
  const searchbar = useSearchBar();
  provide(injectionKey, searchbar);
  return searchbar;
}

export function injectSearchBar() {
  const searchbar = inject(injectionKey);

  if (!searchbar) {
    throw new Error('Injection Error: Searchbar not provided');
  }

  return searchbar;
}

function roughlySameLocation(
  a: Required<GeoCoordinates>,
  b: Required<GeoCoordinates>,
  precision = 3,
) {
  return (
    truncDigits(a.lat, precision) === truncDigits(b.lat, precision) &&
    truncDigits(a.lng, precision) === truncDigits(b.lng, precision)
  );
}

function useSearchBar() {
  let positionKeyword = '';
  let locationKeyword = '';
  const jobsSearchStore = useJobsSearchStore();
  const history = injectHistory();
  const dropdownTarget = ref<Dropdown>('none');
  const positionsGroupedSuggestions = ref<GroupedSuggestions>({ primary: [], secondary: [] });
  const locationGroupedSuggestions = ref<GroupedSuggestions>({ primary: [], secondary: [] });
  const selectedPositions = ref<Suggestion[]>([
    ...jobsSearchStore.filters.static.positions,
    ...jobsSearchStore.filters.static.query.map((q) => ({ id: q, name: q })),
  ]);
  const selectedLocations = ref<Suggestion[]>([...jobsSearchStore.filters.static.locations]);

  function syncStateWithStore(storeState: typeof jobsSearchStore.filters.static) {
    const { positions, locations, query } = storeState;
    selectedPositions.value = [...positions, ...query.map((q) => ({ id: q, name: q }))];
    selectedLocations.value = [...locations];
  }

  async function initPositions() {
    const result = await positionsService.getSuggestions({
      positions: selectedPositions.value.map((s) => s.id),
    });

    if (result.isSuccess) {
      positionsGroupedSuggestions.value = result.data;
    }
  }

  async function initLocations(coordinates?: GeoCoordinates) {
    const result = await locationsService.getSuggestions({
      lat: coordinates?.lat,
      lng: coordinates?.lng,
      locations: selectedLocations.value.map((s) => s.id),
    });

    if (result.isSuccess) {
      locationGroupedSuggestions.value = result.data;
    }
  }

  function isPositionSelected(suggestion: Suggestion) {
    return selectedPositions.value.map((item) => item.id).includes(suggestion.id);
  }

  function isLocationSelected(suggestion: Suggestion) {
    return selectedLocations.value.map((item) => item.id).includes(suggestion.id);
  }

  function togglePosition(position: Suggestion) {
    selectedPositions.value = toggleElementInArray(
      selectedPositions.value,
      position,
      (item) => item.id === position.id,
    );
  }

  function toggleLocation(location: Suggestion) {
    selectedLocations.value = toggleElementInArray(
      selectedLocations.value,
      location,
      (item) => item.id === location.id,
    );
  }

  function createKeyword(text: string) {
    selectedPositions.value.push({
      id: text,
      name: text,
    });
  }

  function open(target: Dropdown) {
    // console.log('open', target);
    dropdownTarget.value = target;
  }

  function close() {
    dropdownTarget.value = 'none';
  }

  function search() {
    const keywords = selectedPositions.value.filter((item) => isString(item.id)) as Item<string>[];
    const positions = selectedPositions.value.filter((item) => isNumber(item.id)) as Item<number>[];
    const locations = selectedLocations.value as Item<string>[];

    jobsSearchStore.setStaticFilterValues({
      query: keywords.map((s) => s.name),
      positions: positions,
      locations: locations,
    });

    navigate({ name: 'search', query: getSearchQueryObject(jobsSearchStore.filters) });
  }

  function getDropdownElement() {
    return document.querySelector('#mp-header-dropdown');
  }

  function togglePointed() {
    const lastPointedElem =
      getDropdownElement()?.querySelectorAll<HTMLElement>('.option--pointed')[0];
    if (lastPointedElem) {
      (lastPointedElem.firstElementChild as HTMLElement)?.click();
    }

    return lastPointedElem;
  }

  function move(direction: 'forward' | 'backward') {
    const dropdownElement = getDropdownElement();
    if (!dropdownElement) return;

    const optionElements = Array.from(dropdownElement.querySelectorAll('.option'));
    if (optionElements.length === 0) return;

    const firstElement = optionElements[0];
    const lastElement = optionElements[optionElements.length - 1];
    const lastPointedOptionElement = dropdownElement.querySelectorAll('.option--pointed')[0];
    const lastPointedOptionElementIndex = optionElements.findIndex(
      (e) => e === lastPointedOptionElement,
    );

    if (direction === 'forward') {
      if (!lastPointedOptionElement) {
        firstElement.classList.add('option--pointed');
      } else if (lastPointedOptionElementIndex === optionElements.length - 1) {
        lastPointedOptionElement.classList.remove('option--pointed');
        firstElement.classList.add('option--pointed');
      } else {
        lastPointedOptionElement.classList.remove('option--pointed');
        optionElements[lastPointedOptionElementIndex + 1].classList.add('option--pointed');
      }
    } else {
      if (!lastPointedOptionElement) {
        lastElement.classList.add('option--pointed');
      } else if (lastPointedOptionElementIndex === 0) {
        lastPointedOptionElement.classList.remove('option--pointed');
        lastElement.classList.add('option--pointed');
      } else {
        lastPointedOptionElement.classList.remove('option--pointed');
        optionElements[lastPointedOptionElementIndex - 1].classList.add('option--pointed');
      }
    }
  }

  function onKeyDown(event: KeyboardEvent, source: 'positions' | 'locations') {
    switch (event.key) {
      case 'Escape':
        close();
        break;
      case 'ArrowDown':
        move('forward');
        break;
      case 'ArrowUp':
        move('backward');
        break;
      case 'Backspace':
        if (source === 'positions' && isEmptyString(positionKeyword)) {
          open('positions-menu');
        } else if (source === 'locations' && isEmptyString(locationKeyword)) {
          open('locations-menu');
        }
        break;
      case 'Enter':
        const pointed = togglePointed();
        if (
          !pointed &&
          ((source === 'positions' && !positionKeyword) ||
            (source === 'locations' && !locationKeyword))
        ) {
          search();
          close();
        }
        break;
      case 'Meta':
      case 'Control':
      case 'Shift':
      case 'Alt':
        break;
      default:
        open(source === 'positions' ? 'positions-autocomplete' : 'locations-autocomplete');
        break;
    }
  }

  function onUpdatePositionKeyword(keyword?: string) {
    positionKeyword = keyword || '';
  }

  function onUpdateLocationKeyword(keyword?: string) {
    locationKeyword = keyword || '';
  }

  watch(() => jobsSearchStore.filters.static, syncStateWithStore, { immediate: true });

  watch(dropdownTarget, (target, previousTarget) => {
    if (previousTarget === 'positions-menu') {
      initPositions();
    } else if (previousTarget === 'locations-menu') {
      initLocations(myGeoLocationService.getLastResolved());
    }
    if (target === 'none') {
      history.init();
    }
  });

  return reactive({
    dropdownTarget,
    positionsGroupedSuggestions,
    locationGroupedSuggestions,
    selectedPositions,
    selectedLocations,
    initPositions,
    initLocations,
    isLocationSelected,
    isPositionSelected,
    togglePosition,
    toggleLocation,
    onUpdatePositionKeyword,
    onUpdateLocationKeyword,
    open,
    close,
    search,
    onKeyDown,
    createKeyword,
  });
}
