<script setup lang="ts">
  import { MpButton, MpText } from '@borg/ui';
  import { GroupedSuggestions, Suggestion } from '@borg/types';
  import { toggleElementInArray } from '@borg/utils';
  import Suggestions from './Suggestions.vue';
  import Option from './Option.vue';

  withDefaults(
    defineProps<{
      selected?: Suggestion[];
      data: GroupedSuggestions[keyof GroupedSuggestions];
      isSelected: (option: Suggestion) => boolean;
    }>(),
    {
      selected: () => [],
    },
  );

  defineEmits<{
    (name: 'suggestion-click', suggestion: Suggestion): void;
  }>();

  const expanded = ref<number[]>([]);

  function onToggleAdditional(gIdx: number) {
    expanded.value = toggleElementInArray(expanded.value, gIdx);
  }
</script>

<template>
  <div class="suggestion-box">
    <slot name="before" />
    <div v-if="false && selected?.length">
      <MpText as="h5">
        {{ $t(`SEARCH_BAR.SUGGESTIONS_BOX.SELECTED_SECTION_LABEL`) }}
      </MpText>
      <Suggestions
        :suggestions="selected"
        :is-selected="isSelected"
        @suggestion-click="$emit('suggestion-click', $event)"
      />
    </div>
    <template
      v-for="(group, gIdx) in data"
      :key="group.key"
    >
      <div v-if="group.items.length">
        <MpText
          as="h5"
          style="margin-bottom: var(--mp-space-20)"
        >
          {{ $t(`SEARCH_BAR.SUGGESTIONS_BOX.${group.key.toUpperCase()}_SECTION_LABEL`) }}
        </MpText>
        <div>
          <Suggestions
            :suggestions="group.items"
            :is-selected="isSelected"
            @suggestion-click="$emit('suggestion-click', $event)"
          />
          <Suggestions
            v-if="expanded.includes(gIdx)"
            :suggestions="group.additionalItems"
            :is-selected="isSelected"
            @suggestion-click="$emit('suggestion-click', $event)"
          />
          <Option v-if="group.additionalItems.length > 0">
            <MpButton
              :text="expanded.includes(gIdx) ? $t('LESS') : $t('MORE')"
              :left-icon="expanded.includes(gIdx) ? 'chevron_up' : 'chevron_down'"
              size="sm"
              variant="ghost"
              @click="onToggleAdditional(gIdx)"
            />
          </Option>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .suggestion-box {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-60);
    margin-top: var(--mp-space-60);
    @include screen-lg {
      margin-top: 0;
    }
  }

  .suggestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--mp-space-40);
    padding-right: var(--mp-space-80);

    &--selected {
      color: rgb(136, 206, 229);
    }
  }
</style>
